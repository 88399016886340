<div
   class="preview"
   *ngIf="showPreview">
   <app-search-results-preview
      [searchText]="searchText"
      (seeAllResults)="seeAllResults()"></app-search-results-preview>
</div>

<nz-layout>
   <div class="search-results">
      <div
         #searchBox
         [ngClass]="{
            'search-box-padding': true,
         }">
         <div
            [ngClass]="{
               'search-box': true,
               'search-box-with-suggestions': suggestions.length > 0,
            }">
            <div
               data-id="search-input-box"
               [ngClass]="{
                  'search-input-box': true,
                  'search-box-default': suggestions.length == 0 && searchTerm == '' && suggestionSelected == false,
                  'search-box-filled': suggestionSelected == true,
                  'search-box-focussed': suggestions.length > 0,
                  'search-box-typing': suggestions.length == 0 && searchTerm != '' && suggestionSelected == false,
               }">
               <div class="search-icon">
                  <span
                     nz-icon
                     nzType="search"
                     nzTheme="outline"></span>
               </div>
               <div class="search-input-text-div">
                  <input
                     #searchInputField
                     type="text"
                     [ngClass]="{
                        'search-input-text': true,
                        'search-input-default':
                           suggestions.length == 0 && searchTerm == '' && suggestionSelected == false,
                        'search-input-filled': suggestionSelected == true,
                        'search-input-focussed': suggestions.length > 0,
                        'search-input-typing':
                           suggestions.length == 0 && searchTerm != '' && suggestionSelected == false,
                     }"
                     placeholder="Search NewsConnect..."
                     (input)="onInput($event)"
                     (focus)="onFocus()"
                     (keydown.enter)="setSearchTerm($event)"
                     [(ngModel)]="searchTerm" />
               </div>

               <!-- <div class="search-input-text-div">
                  <div
                     [ngClass]="{
                        'search-input-text': true,
                        'search-input-default':
                           suggestions.length == 0 && searchTerm == '' && suggestionSelected == false,
                        'search-input-filled': suggestionSelected == true,
                        'search-input-focussed': suggestions.length > 0,
                        'search-input-typing':
                           suggestions.length == 0 && searchTerm != '' && suggestionSelected == false,
                     }"
                     placeholder="Search NewsConnect..."
                     contenteditable="true"
                     (input)="onInput($event)"
                     (focus)="onFocus()"
                     (keydown.enter)="setSearchTerm($event)">
                  </div>
               </div> -->
               <div
                  class="close-icon"
                  *ngIf="searchTerm != ''">
                  <button (click)="clearSearchTerm()">
                     <i
                        nz-icon
                        nzType="close-circle"
                        nzTheme="fill"></i>
                  </button>
               </div>
            </div>

            <div
               class="suggestions-list"
               data-id="search-suggestions"
               *ngIf="suggestions.length > 0">
               <ul class="flex-suggestions">
                  <li
                     class="suggestion-item"
                     *ngFor="let suggestion of suggestions">
                     <div class="history-icon">
                        <button>
                           <i
                              nz-icon
                              nzType="history"
                              nzTheme="outline"></i>
                        </button>
                     </div>
                     <div
                        (click)="selectSuggestion(suggestion)"
                        class="suggestion-text">
                        {{ suggestion }}
                     </div>
                     <div
                        class="delete"
                        data-id="suggestion-delete-icon">
                        <button (click)="deleteSuggestionFromStorage(suggestion)">
                           <i
                              nz-icon
                              nzType="close-circle"
                              nzTheme="fill"></i>
                        </button>
                     </div>
                  </li>
               </ul>
            </div>

            <ng-template #prefixTemplateUser>
               <i
                  nz-icon
                  nzType="search"
                  class="search-icon"
                  nzTheme="outline"></i>
            </ng-template>
            <ng-template #suffixTemplateInfo>
               <button (click)="clearSearchTerm()">
                  <i
                     nz-icon
                     nzType="close-circle"
                     nzTheme="fill"
                     class="close-circle"></i>
               </button>
            </ng-template>
         </div>
      </div>
      <div
         [ngClass]="{
            'filter-icon': true,
         }">
         <button (click)="toggleFilterDrawer()">
            <i
               nz-icon
               [class.collapsed]="isFilterDrawerCollapsed"
               nzType="filter"
               nzTheme="outline"></i>
         </button>
      </div>
   </div>

   <nz-layout [ngClass]="{ 'ant-layout-has-sider': true }">
      <nz-content>
         <div class="search-results-tabs">
            <nz-tabset
               (nzSelectChange)="onChangeTab($event)"
               [(nzSelectedIndex)]="tabIndex">
               <!----------- STORIES ----------->

               <nz-tab nzTitle="Stories">
                  <app-stories-results [isVisible]="tabIndex === 0"></app-stories-results>
               </nz-tab>

               <!----------- POSTS ----------->

               <nz-tab nzTitle="Posts">
                  <app-post-results [isVisible]="tabIndex === 1"></app-post-results>
               </nz-tab>

               <!----------- ANGLES ----------->

               <nz-tab [nzTitle]="titleTemplate">
                  <app-angle-results [isVisible]="tabIndex === 2"></app-angle-results>
                  <ng-template #titleTemplate>Angles</ng-template>
               </nz-tab>

               <!----------- GROUPS ----------->

               <nz-tab nzTitle="Groups">
                  <app-group-results [isVisible]="tabIndex === 3"></app-group-results>
               </nz-tab>

               <!----------- PEOPLE ----------->

               <nz-tab nzTitle="People">
                  <app-people-results [isVisible]="tabIndex === 4"></app-people-results>
               </nz-tab>
            </nz-tabset>
         </div>
      </nz-content>

      <!-- SEARCH FILTERS DESKTOP -->
      <nz-sider
         *ngIf="!isMobile && tabIndex !== 3 && tabIndex !== 4"
         nzCollapsible
         nzCollapsedWidth="0"
         class="search-filter-container"
         [nzWidth]="320"
         [nzCollapsed]="isFilterDrawerCollapsed"
         [nzTrigger]="null">
         <app-search-filters
            [isCollapsed]="isFilterDrawerCollapsed"
            (closeFilter)="closeFilterDrawer()"
            (triggerFilter)="applyFilters()"
            [filterState]="tabOrder[tabIndex]"></app-search-filters>
      </nz-sider>
   </nz-layout>
</nz-layout>

<!-- SEARCH FILTERS MOBILE -->
<nz-drawer
   *ngIf="isMobile && tabIndex !== 3 && tabIndex !== 4"
   nzPlacement="right"
   nzWrapClassName="search-filter-container drawer"
   [nzClosable]="false"
   [nzVisible]="!isFilterDrawerCollapsed"
   [nzMaskClosable]="true"
   [nzWidth]="320"
   (nzOnClose)="closeFilterDrawer()">
   <ng-container *nzDrawerContent>
      <app-search-filters
         [isDrawer]="true"
         [filterState]="tabOrder[tabIndex]"
         (triggerFilter)="applyFilters()"
         (closeFilter)="closeFilterDrawer()"></app-search-filters>
   </ng-container>
</nz-drawer>
