<div #container class="view-attachments"
[ngClass]="{ 'auto-rows': showAllAttachments, 'fixed-rows': !showAllAttachments }">

  <ng-container *ngFor="let attachment of lightBoxAttachments; index as currentIndex">

    <!-- IMAGE -->
    <button class="attachment" *ngIf="attachment.isImage" (click)="openlightbox(currentIndex,attachment.attachmentID)">
      <img [src]="attachment.previewUrl" [title]="attachment.attachmentName" [alt]="attachment.attachmentName" />
    </button>

    <!-- VIDEO -->
    <button class="attachment" *ngIf="attachment.isthumpVideo && attachment.attachmentPreviewName !== ''"
      (click)="openvideolightbox(currentIndex,attachment.fileUrl,attachment.extension,attachment.attachmentID,attachment.attachmentName)">
      <img *ngIf="attachment.isthumpVideo && attachment.attachmentPreviewName !== ''" [src]="attachment.previewUrl" [title]="attachment.attachmentName">
      <app-video-play-button [attachmentName]="attachment.attachmentName"></app-video-play-button>
    </button>

    <button class="attachment" *ngIf="(attachment.isthumpVideo || attachment.isVideo) && attachment.attachmentPreviewName === ''"
      (click)="openvideolightbox(currentIndex,attachment.fileUrl,attachment.extension,attachment.attachmentID,attachment.attachmentName)">
      <app-video-play-button [attachmentName]="attachment.attachmentName"></app-video-play-button>
    </button>
    
    <!-- Doc FILES -->
    <button class="attachment"
      *ngIf="attachment.isFile && attachment.attachmentPreviewName !== ''"
      (click)="openlightbox(currentIndex,attachment.attachmentID)">
      <img [src]="attachment.thumpnail" [title]="attachment.attachmentName">
    </button>

    <button class="attachment"
      *ngIf="attachment.isFile && attachment.attachmentPreviewName === ''"
      (click)="openlightbox(currentIndex,attachment.attachmentID)">
      <i [title]="attachment.attachmentName" class="icon" nz-icon nzType="{{attachment.attachmentName | fileIcon}}"
        nzTheme="outline"></i>
    </button>

    <!-- OTHER FILES -->
    <a *ngIf="!attachment.isImage && !attachment.isVideo  && !attachment.isthumpVideo && !attachment.isFile" href="{{attachment.fileUrl}}" target="_blank"
      class="attachment">
      <span [title]="attachment.attachmentName" nz-icon nzType="{{attachment.attachmentName | fileIcon}}" nzTheme="outline"></span>
    </a>


  </ng-container>

</div>

<!-- LIGHTBOX -->

<app-lightbox *ngIf="showLightBox" [indexToOpenOnLoad]="indexToOpen" [lightboxAttachments]="lightBoxAttachments"
  (modalClosed)="destroyModalPopup($event)"></app-lightbox>