import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-play-button',
  templateUrl: './video-play-button.component.html',
  styleUrl: './video-play-button.component.scss'
})
export class VideoPlayButtonComponent implements OnInit {

  ngOnInit(): void {

    throw new Error('Method not implemented.');
  
  }
  
  @Input() attachmentName: string;

}
