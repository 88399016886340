import { Component, HostListener, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { GroupSearchResult, ISearch } from '@models/ncx/global-search';
import { GlobalSearchService } from '@services/global-search.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { UrlRedirectService } from '@services/url-redirect.service';
import { Common } from '@utilities/common';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { SubSink } from 'subsink';

type GroupParams = {
  contentType: 'Group',
  startIndex: number,
  endIndex: number,
  sortField: string,
  sortOrder: string
};

type GroupPayload = object;

@Component({
  selector: 'app-group-results',
  templateUrl: './group-results.component.html',
  styleUrls: ['../search-results-common.scss']
})
export class GroupResultsComponent implements OnChanges, OnDestroy {

  @Input() isVisible: boolean = false;

  // Do we need to load additional items to engage the scrollbar?
  initLoadingMoreForScroll: boolean = false;

  // Search Results from API
  searchResults: GroupSearchResult[] = [];

  // Current page of search results
  page: number = 1;

  // Total number of results
  total: number = 0;

  // Number of results per page
  limit: number = 24;

  // Loader
  isLoaded: boolean = true;

  screenSize = 0;

  private subs = new SubSink();
  // Listens for changes to filter changes for debouncing
  filterChange = new Subject();
  // Cancel API request
  cancelRequest: Subject<boolean> = new Subject<boolean>();
  sentAnalyticsEvent: boolean = false;
  //scroll distance
  scrollDistance: number = 2;
  public readonly Common = Common;
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler($event: BeforeUnloadEvent) {
    if (this.searchService.searchTerm.value !== undefined
      && this.searchService.searchTerm.value.length > 0
      && !this.sentAnalyticsEvent) {
      console.log("Close Tab")
      this.gaService.sendEvent('View Group Click', 'Group Search Result Page', this.searchService.searchTerm.value, 0);
    }
  }
  constructor(
    private router: Router,
    private toastService: ToastService,
    public searchService: GlobalSearchService,
    private urlRedirectService: UrlRedirectService,
    private gaService: GoogleAnalyticsEventService
  ) {

    const screenWidth = window.innerWidth;

    if(screenWidth > 1272)
    {
      this.limit = 48;
    }
    console.log("screenWidth"+screenWidth);
    // When search term changes, load results if component is visible
    this.subs.sink = this.searchService.searchTerm.subscribe(() => {

      // When search term changes, reset results regardless of whether the component is visible or not
      // When the user returns to this tab, a new list of search results will load
      this.resetResults();
      if (this.isVisible) {

        this.cancelRequest.next(true);
        this.loadSearchResults();

      }

    });
    
    this.filterChange.pipe(debounceTime(750)).subscribe(() => {
      this.searchService.filterChanged.next();
    });

    // When filters change, load results if component is visible
    this.subs.sink = this.searchService.filterChanged.subscribe(() => {

      if (this.isVisible) {

        this.cancelRequest.next(true);
        this.resetResults();
        this.loadSearchResults();

      }

    });


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log("NavigationStart")
        if (this.searchService.searchTerm.value !== undefined
          && this.searchService.searchTerm.value.length > 0
          && !this.sentAnalyticsEvent) {
          this.gaService.sendEvent('View Group Click', 'Group Search Result Page', this.searchService.searchTerm.value, 0);
        }
      }
    });

  }

  onFilterChanged() {
    this.filterChange.next('');
  }
  
  ngOnChanges(changes: SimpleChanges) {

    // When visibility changes, load search results if visible and if the results weren't already loaded
    if ('isVisible' in changes) {

      if (changes.isVisible.currentValue && !this.searchResults.length) {

        this.loadSearchResults();

      }
      if (!changes.isVisible.currentValue) {

        this.cancelRequest.next(true);

      }

    }

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }

  /**
   * Load search results
   *
   */
  loadSearchResults() {

    this.isLoaded = false;

    const params = this.buildParams();

    const payload = this.buildPayload();

    const queryString = Object.keys(params).map((key: string) => `${key}=${params[key]}`).join('&');

    this.searchService.loadSearchResults(queryString, payload).pipe(takeUntil(this.cancelRequest.pipe(filter((value) => value === true)))).subscribe({
      next: (res: any) => {

        const results: ISearch = res as ISearch;

        const searchResults = results.groupSearchResult || [];

        this.searchResults = this.page === 1 ? searchResults : [...this.searchResults, ...searchResults];

        this.total = results.groupCount;

        console.log('loadSearchResults', { searchResults: this.searchResults, total: this.total });

        this.searchService.setSuggestedSearch(results.contentSuggestedPhrase);

        this.sendGATracking(params, payload);

      },
      error: (error: any) => {

        this.toastService.createMessage('error', 'Unable to load search results');

        console.error('loadSearchResults', error);

      }
    }).add(() => {

      this.isLoaded = true;

      console.log('Finalization callback called', this.page);

      // Do this whenever we're at Page 1 in order to fill the viewport with scrollable content
      if (this.page === 1) {

        setTimeout(() => {

          this.initLoadingMoreForScroll = false;
          this.checkIfShouldLoadMore();

        }, 1000);
      }

    });

  }

  /**
   * If there isn't enough content in the viewport to allow the user to scroll, call the onLoadMore
   *  function to force more content to load automatically.
   *
   */
  checkIfShouldLoadMore() {

    if (this.initLoadingMoreForScroll) {

      return;

    }

    this.initLoadingMoreForScroll = true;

    const storyContainer = document.getElementById('groupContainer');

    const scrollableContainer = document.getElementById('scrollableContainer');

    // const scrollableContainer = document.querySelector('[data-scrollable-container]');

    if (!storyContainer || !scrollableContainer) {

      return;

    }

    const container = storyContainer.getBoundingClientRect();

    const scrollable = scrollableContainer.getBoundingClientRect();

    console.log('heights', { container: container.height, scrollable: scrollable.height });

    if (container.height < scrollable.height && this.page * this.limit < this.total) {

      console.log('Should Load More', { container: container.height, scrollable: scrollable.height });
      this.onLoadMore();
      this.initLoadingMoreForScroll = true;

    }

  }

  /**
 * Scroll Down Event call when page is scroll down, fetch the next set of records.
 *
 */
  onLoadMore() {

    this.page += 1;

    this.loadSearchResults();

  }

  /**
   * View Group event
   *
   */
  viewGroup(group: GroupSearchResult, index: number) {

    const searchText = this.searchService.searchTerm.value;

    this.gaService.trackSelectedContentforPageNumberandIndex(index + 1, searchText, 'Selected Group Index', 'Group Content');
    this.gaService.trackSelectedContentforPageNumberandIndex(this.page, searchText, 'Selected Group Page Number', 'Group Content');
    this.gaService.sendEvent('View Group Click', 'Group Search Result Page', searchText, 1);
    this.sentAnalyticsEvent = true;
  }

  /**
   * Build the query string portion of the API call
   *
   */
  buildParams(): GroupParams {

    // API Params
    const params: GroupParams = {
      contentType: 'Group',
      startIndex: 1,
      endIndex: 24,
      sortField: 'modificationdate',
      sortOrder: 'ascending'
    };

    try {

      // Start/End Index
      const { start, end } = this.startEndIndex;

      params.startIndex = start;
      params.endIndex = end;

      // Sort
      const { sort } = this.searchService.filters.GROUPS;

      params.sortOrder = sort;

      console.log('buildParams', params);

      return params;

    } catch (error) {

      console.error('buildParams', error);

      return {} as GroupParams;

    }

  }

  /**
   * Build payload for API call
   *
   */
  buildPayload(): GroupPayload {

    return {} as GroupPayload;

  }

  /**
   * Track Google Analytics
   *
   */
  sendGATracking(params: GroupParams, payload: GroupPayload) {

    const searchTerm = this.searchService.searchTerm.value;

    console.log('Google Analytics Tracking (Groups)', { searchTerm, params, payload });

    this.gaService.trackContentResultsCount(searchTerm, this.total, 'Group Results Count', 'Group Content');

    // Sort
    if (params.sortOrder) {

      this.gaService.trackSelectedContentFilter(searchTerm, `Sort - ${params.sortOrder}`, this.total, 'Group Content');

    }

  }

  /**
   * On page scroll, load next page
   *
   */
  onScrollDown() {

    const { end } = this.startEndIndex;

    console.log('onScrollDown (Groups)');

    // If the end index is >= number of results
    if (end >= this.total) {

      console.log('No More Results');
      return;

    }

    this.page += 1;
    this.scrollDistance = 0;
    setTimeout(() => { this.scrollDistance = 2 }, 500);
    this.cancelRequest.next(false);
    this.loadSearchResults();

  }

  /**
   * Reset all results
   *
   */
  resetResults() {

    this.total = 0;
    this.page = 1;
    this.searchResults = [];

  }

  get resultCount(): string {

    return `${Common.formatNumber(this.total)} Result${this.total !== 1 ? 's' : ''}`;

  }

  get startEndIndex(): { start: number, end: number } {

    const end = this.page * this.limit;

    const start = (end - this.limit) + 1;

    return { start, end };

  }

}
