<nz-carousel #carousel class="view-thumbnails-container" nzEffect="fade" [nzDots]="attachments.length > 1">

  <!-- NO ATTACHMENTS -->
  <div *ngIf="!attachments.length" nz-carousel-content class="attachment-icon mb-5">
    <i nz-icon nzType="file-unknown" class="icon" nzTheme="outline" style="width: 100%;"></i>
  </div>

  <!-- HAS ATTACHMENTS -->
  <ng-container *ngIf="attachments.length">

    <div nz-carousel-content *ngFor="let attachment of attachments; index as currentIndex">

      <span class="view-thumbnails-overlay">

        <!-- prev/next buttons -->
        <ng-container *ngIf="attachments.length > 1">

          <!-- Previous Button -->
          <button class="carousel-button left" (click)="pre()">
            <i nz-icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M15 18l-6-6 6-6" />
              </svg>
            </i>
          </button>

          <!-- Next Button -->
          <button class="carousel-button right" (click)="next()">
            <i nz-icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M9 18l6-6-6-6" />
              </svg>
            </i>
          </button>

        </ng-container>

        <!-- IMAGE -->
        <button *ngIf="attachment.isImage" class="video-attachment" (click)="openlightbox(currentIndex)">
          <img class="video-attachment" [alt]="attachment.attachmentName"  [alt]="attachment.attachmentName" [src]="attachment.previewUrl" [title]="attachment.attachmentName"/>
        </button>

        <!-- VIDEO -->
        <button *ngIf="attachment.isthumpVideo && attachment.attachmentPreviewName !== ''" class="video-attachment"
        (click)="openvideolightbox(currentIndex,attachment.fileUrl,attachment.extension)">
          <img *ngIf="attachment.isthumpVideo && attachment.attachmentPreviewName !== ''" class="video-attachment" [src]="attachment.thumpnail" [title]="attachment.attachmentName"
            [alt]="attachment.attachmentName" />
          <app-video-play-button [attachmentName]="attachment.attachmentName"></app-video-play-button>
        </button>

        <button *ngIf="(attachment.isthumpVideo || attachment.isVideo) && attachment.attachmentPreviewName === ''" class="attachment-icon"
        (click)="openvideolightbox(currentIndex,attachment.fileUrl,attachment.extension)">
          <app-video-play-button [attachmentName]="attachment.attachmentName"></app-video-play-button>
        </button>

        <!-- NEITHER IMAGE NOR VIDEO -->
        <!-- <a *ngIf="!attachment.isVideo && !attachment.isImage"
           class="doc-attachment"
           [style.background-image]="'url('+attachment.thumpnail+')'">>
         
        </a> -->

        <!-- Doc FILES -->
        <button class="video-attachment" 
          *ngIf="attachment.isFile && attachment.attachmentPreviewName !== ''" (click)="openlightbox(currentIndex)">
          <img class="video-attachment" [src]="attachment.thumpnail" [title]="attachment.attachmentName">
        </button>

        <button class="video-attachment" 
          *ngIf="attachment.isFile && attachment.attachmentPreviewName === ''" (click)="openlightbox(currentIndex)">
          <i [title]="attachment.attachmentName" class="" nz-icon nzType="{{attachment.attachmentName | fileIcon}}"
            nzTheme="outline"></i>
        </button>


        <!-- OTHER FILES -->
        <a *ngIf="!attachment.isImage && !attachment.isFile && !attachment.isthumpVideo" href="{{attachment.fileUrl}}"
          target="_blank" class="doc-attachment" >
          <i [title]="attachment.attachmentName" class="icon" nz-icon nzType="{{attachment.attachmentName | fileIcon}}"
            nzTheme="outline"></i>
        </a>
      </span>

    </div>

  </ng-container>

</nz-carousel>

<app-lightbox *ngIf="showLightBox" [lightboxAttachments]="attachments" [indexToOpenOnLoad]="indexToOpen"
  (modalClosed)="destroyModalPopup($event)"></app-lightbox>